export enum Colors {
  White = "#FFFFFF",
  Black = "#000000",
  Yellow = '#FFBA49',
  // Green = "#419D78",
  Green = '#42BB83',
  OffWhite = "#FCFCFC",
  LightBlue = "#39159C",
  Blue = "#2D0E84",
  DarkBlue = "#1D0858",
  Red = "#E63946",
  Orange = "#FF700F",
  FadedOrange = '#FFF1E7',
  LightOrange = "#FFBE93",
  DarkOrange = "#D65600",
  Grey1 = "#333333",
  Grey2 = "#4F4F4F",
  Grey3 = "#828282",
  Grey4 = "#BDBDBD",
  Grey5 = "#E0E0E0",
  Grey6 = "#F2F2F2",
  Grey7 = "#F8F8F8",
  Purple = "#040436",
}
